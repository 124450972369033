import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-flow: wrap column;
  width: 100%;
`;

export const Header = styled.div`
  position: relative;
  margin-bottom: 40px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    z-index: 1;
  }
`;

export const HeaderImage = styled.img`
  display: block;
  width: 100%;
`;

export const Section = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['isPaddingBottom', 'isPaddingTop', 'padding', 'color'].includes(prop),
})<{
  color?: string;
  isPaddingBottom?: boolean;
  isPaddingTop?: boolean;
  padding?: string;
  bck?: string;
}>`
  display: flex;
  flex-flow: wrap column;
  padding-top: ${({ isPaddingTop }) => (isPaddingTop ? '80px' : '0')};
  padding-bottom: ${({ isPaddingBottom }) => (isPaddingBottom ? '80px' : '0')};
  background: ${({ color }) => color || 'white'};

  @media (max-width: 768px) {
    padding: ${({ padding }) => padding};
    padding-top: ${({ isPaddingTop }) => (isPaddingTop ? '40px' : '0')};
    padding-bottom: ${({ isPaddingBottom }) =>
      isPaddingBottom ? '40px' : '0'};
  }
`;

export const Title = styled.h2.withConfig({
  shouldForwardProp: (prop) =>
    !['color', 'fontSize', 'isCenter', 'margin', 'mobileFontSize'].includes(
      prop,
    ),
})<{
  color?: string;
  fontSize?: number;
  mobileFontSize?: number;
  isCenter?: boolean;
  margin?: number;
}>`
  font-family:
    Chakra Petch,
    sans-serif;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '42px')};
  line-height: 1.2;
  text-align: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
  margin-top: 0;
  margin-bottom: ${({ margin }) => (margin ? `${margin}px` : '12px')};
  color: ${({ color }) => color || 'white'};

  @media (max-width: 768px) {
    font-size: ${({ mobileFontSize }) =>
      mobileFontSize ? `${mobileFontSize}px` : '32px'};
    text-align: center;
  }
`;

export const Subtitle = styled.p<{ color?: string }>`
  font-family:
    Chakra Petch,
    sans-serif;
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  margin: 0;
  color: ${({ color }) => color || 'white'};

  @media (max-width: 768px) {
    font-size: 18px !important;
  }
`;

export const Footer = styled.div`
  padding: 80px 0;
  background: #000000;
`;

export const Copyright = styled.div`
  padding: 34px 0 40px 0;
  background: #ffffff;
`;

export const ImagePoster = styled.img`
  display: block;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    object-fit: cover;
  }
`;

export const ImagePosterMobile = styled.img`
  display: block;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    object-fit: cover;
  }
`;

export const Paragraph = styled.p.withConfig({
  shouldForwardProp: (prop) => !['fontSize', 'margin', 'center'].includes(prop),
})<{
  fontSize?: number;
  margin?: string;
  center?: boolean;
}>`
  margin: ${({ margin }) => margin || '0 0 30px 0'};
  font-family:
    Chakra Petch,
    sans-serif;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '20px')};
  line-height: 1.5;

  @media (max-width: 768px) {
    text-align: ${({ center }) => (center ? 'center' : 'left')};
  }
`;

export const GridCenter = styled.div`
  display: flex;
  flex-flow: wrap column;
  height: 100%;
  justify-content: center;
`;

export const ButtonWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isCenter'].includes(prop),
})<{
  isCenter?: boolean;
}>`
  display: flex;
  flex-flow: wrap row;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'flex-start')};
  width: 100%;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const AccordionTitle = styled.span`
  font-family:
    Chakra Petch,
    sans-serif;
  font-size: 24px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const BigPoster = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    height: 428px;
  }
`;

export const LogoGromda = styled.img.withConfig({
  shouldForwardProp: (prop) => !['height'].includes(prop),
})<{
  height: number;
}>`
  display: block;
  width: auto;
  height: ${({ height }) => (height ? `${height}px` : '100%')};

  @media (max-width: 768px) {
    height: 70px !important;
  }
`;

export const AbsoluteBox = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  width: 100%;
  display: flex;
  flex-flow: wrap column;
  align-items: center;
  gap: 50px;

  @media (max-width: 768px) {
    bottom: 40px;
    gap: 20px;
  }
`;

export const Logotypes = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

export const Logotype = styled.img`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

export const CopyrightWrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media (max-width: 768px) {
    flex-flow: wrap column-reverse;
    gap: 16px;
  }
`;

export const StyledLink = styled.span.withConfig({
  shouldForwardProp: (prop) =>
    !['color', 'fontSize', 'fontFamily'].includes(prop),
})<{
  color?: string;
  fontSize?: string;
  fontFamily?: string;
}>`
  display: inline-block;
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  text-decoration: underline;
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
  line-height: 1.5;
  color: ${({ color }) => color || '#000000'};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const Text = styled.span`
  font-family: 'Reddit Sans', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #000000;
`;

export const Href = styled.a`
  display: inline;
  line-height: 1.5;
  color: #000000;
  text-decoration: underline;
  font-family:
    Chakra Petch,
    sans-serif;
  font-size: 16px;
`;

export const FortunaBet = styled.img`
  display: block;
  width: auto;
  max-width: 100%;
  height: 200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

export const Info = styled.div`
  font-family:
    Chakra Petch,
    sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  margin-top: 40px;
  text-align: center;
`;

export const EmailLink = styled.a`
  display: inline;
  font-family:
    Chakra Petch,
    sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  text-decoration: underline;
`;

export const ImageFullWidth = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;
