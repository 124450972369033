import React from 'react';
import * as Styles from './styles';

interface Props {
  number: number;
}

const CounterCodes: React.FC<Props> = ({ number }) => {
  return (
    <Styles.Wrapper>
      <Styles.Text>Zostało tylko</Styles.Text>
      <Styles.Number>{number}</Styles.Number>
      <Styles.Text>kodów!</Styles.Text>
    </Styles.Wrapper>
  );
};

export default CounterCodes;
