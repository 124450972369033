import React from 'react';
import { Image, Wrapper } from '@components/OldCampaigns/styles';

interface Props {
  images: string[];
}

const OldCampaigns: React.FC<Props> = ({ images }) => {
  return (
    <Wrapper>
      {images.map((image, i) => (
        <Image key={i} src={image} />
      ))}
    </Wrapper>
  );
};

export default OldCampaigns;
