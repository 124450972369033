import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 50px;
  position: absolute;
  bottom: 80px;
  left: 0;
  z-index: 100;

  @media (max-width: 768px) {
    bottom: 0;
    gap: 14px;
  }
`;

export const And = styled.span`
  font-family:
    Chakra Petch,
    sans-serif;
  font-size: 96px;
  line-height: 1.2;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 12px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

export const Logo1 = styled.img`
  display: block;
  width: auto;
  height: 125px;

  @media (max-width: 768px) {
    height: 58px;
  }
`;

export const Logo2 = styled.img`
  display: block;
  width: auto;
  height: 88px;

  @media (max-width: 768px) {
    height: 44px;
  }
`;
