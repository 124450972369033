import React from 'react';
import {
  Content,
  HeaderImage,
  Section,
  Header,
  Title,
  Subtitle,
  GridCenter,
  Paragraph,
  ButtonWrapper,
  ImagePoster,
  AccordionTitle,
  Href,
  Footer,
  Logotypes,
  Logotype,
  Copyright,
  CopyrightWrapper,
  Text,
  StyledLink,
  ImageFullWidth,
  // Info,
  // EmailLink,
} from '@styles/layoutGromda.styles';
import Container from '@mui/material/Container';
import LogoAndLogo from '@components/LogoAndLogo';
// import LayoutRegisterFormSwitcher from '@src/layouts/RegisterForm';
import CounterCodes from '@components/CounterCodes';
import Box from '@mui/material/Box';
import Legal from '@components/Legal';
import Grid from '@mui/material/Grid';
import Opinion from '@components/Opinion';
import Button from '@components/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { handleOpenPdf } from '@utils/helpers';
import OldCampaigns from '@components/OldCampaigns';

const LayoutPrimeShowMMA = () => {
  document.title = 'PRIME 11 & FORTUNA';

  const handleScroll = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Content>
      <Section color="#000">
        <Container
          sx={{
            padding: {
              xs: 0,
            },
          }}
        >
          <Header>
            <HeaderImage src="/images/prime/header.jpg" />
            <LogoAndLogo
              firstLogo="/images/logotypes/prime-big.png"
              secondLogo="/images/logotypes/fortuna.png"
            />
          </Header>
        </Container>
      </Section>

      <Section color="#000000" isPaddingBottom id="form" padding="0 40px">
        <Container>
          <Title isCenter>Jak odebrać PPV na Prime 11 ?</Title>
          <Subtitle>
            1. Wypełnij formularz - otrzymasz unikalny link rejestracyjny
          </Subtitle>
          <Subtitle>
            2. Zarejestruj z linku nowe konto w Fortuna i wpłać 20 zł depozytu
          </Subtitle>
          <Subtitle>
            3. Zweryfikuj konto i zagraj kupon za 20 zł z dowolną walką na Prime
            11
          </Subtitle>
          <Subtitle>4. Po weryfikacji, otrzymasz mail z kodem PPV</Subtitle>
          <Box sx={{ marginTop: 4 }}>
            <CounterCodes number={0} />
          </Box>
        </Container>
      </Section>

      <Section color="#000000" isPaddingBottom id="form" padding="0 40px">
        <Container>
          <Title isCenter>Kampania dobiegła końca</Title>
        </Container>
      </Section>

      {/*<Section color="#000000" isPaddingBottom>*/}
      {/*  <Container>*/}
      {/*    <LayoutRegisterFormSwitcher layoutName="default" />*/}
      {/*    <Info>*/}
      {/*      W razie pytań lub problemów z rejestracją, napisz do nas -{' '}*/}
      {/*      <EmailLink href="mailto:ppv@chaosmedia.pl">*/}
      {/*        ppv@chaosmedia.pl*/}
      {/*      </EmailLink>*/}
      {/*      .*/}
      {/*    </Info>*/}
      {/*  </Container>*/}
      {/*</Section>*/}

      <Section color="#000000">
        <Container>
          <Title isCenter>Postaw na swoich faworytów</Title>

          <Href href="https://online.efortuna.pl/page?key=ej0xNTc1Mzg3MyZsPTE1OTY3MDU5JnA9OTIyNTM%3D">
            <ImageFullWidth src="/images/prime/szachta_soroko.png" />
          </Href>

          <Href href="https://online.efortuna.pl/page?key=ej0xNTc1Mzg3MyZsPTE1OTY3MDU5JnA9OTIyNTM%3D">
            <ImageFullWidth src="/images/prime/polishzombie_donkasjo.png" />
          </Href>
        </Container>
      </Section>

      <Section color="#F8F9FF" isPaddingBottom isPaddingTop>
        <Container>
          <Legal />

          <Grid
            container
            spacing={2}
            sx={{
              marginTop: {
                xs: 0,
                md: '40px',
              },
            }}
          >
            <Grid item xs={12} md={4}>
              <Opinion text="Nie spodziewałem się, że to będzie takie proste. Kod PPV działał od razu, a ja obejrzałem świetną galę KSW 100 za friko. Mega fajna akcja, oby więcej takich!" />
            </Grid>
            <Grid item xs={12} md={4}>
              <Opinion text="Dzięki za możliwość obejrzenia Gromdy, szkoda tylko przegranej Jokera." />
            </Grid>
            <Grid item xs={12} md={4}>
              <Opinion text="Kod dostałam szybko. Dzięki za możliwość, mam nadzieję, że to nie ostatni raz!" />
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section isPaddingBottom isPaddingTop>
        <Container>
          <Grid container spacing={'80px'}>
            <Grid item xs={12} md={6}>
              <GridCenter>
                <Title color="#000" fontSize={48} margin={24}>
                  PRIME 11
                </Title>
                <Paragraph center>
                  Federacja PRIME SHOW MMA powraca już 11 stycznia 2025 z
                  kolejną wyjątkową edycją! Gala PRIME 11 będzie obfitować w
                  wiele elektryzujących pojedynków i wyczekiwanych rewanżów. W
                  walce wieczoru dojdzie do ostatecznego rozwiązania konfliktu
                  Don Kasjo z Marcinem Wrzoskiem. Jest to długo wyczekiwany
                  rewanż za pierwszą porażkę Polish Zombie we freakfightach! W
                  co-main evencie gali dojdzie do rozwiązania głośnego
                  konfliktu: Paweł „Prezes” Jóźwiak vs Arek Tańcula.
                </Paragraph>
                <Paragraph center>
                  Do walk powróci dawno niewidziany Adam Soroko, który również
                  stoczy głośny rewanż. Jego rywalem będzie Szachta! To po tej
                  walce Soroko poznała cała Polska.
                </Paragraph>
                <Paragraph center>
                  Ponadto podczas gali w Ostrowie Wielkopolskim pojawią się
                  największe gwiazdy federacji.
                </Paragraph>
                <Paragraph center>
                  Karta PRIME jak zwykle będzie wypełniona po brzegi w kipiące
                  dymami walki. Musicie to zobaczyć na żywo!
                </Paragraph>

                <ButtonWrapper>
                  <Button
                    text="Zarejestruj się"
                    color="error"
                    size="large"
                    onClick={() => handleScroll('form')}
                  />
                </ButtonWrapper>
              </GridCenter>
            </Grid>

            <Grid item xs={12} md={6}>
              <ImagePoster src="/images/prime/prime-poster.jpg" />
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section isPaddingBottom isPaddingTop>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Title color="#000" fontSize={48} mobileFontSize={48} margin={24}>
                FAQs
              </Title>
            </Grid>
            <Grid item xs={12} md={7}>
              <Accordion sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <AccordionTitle>Jakie są warunki promocji ?</AccordionTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <Paragraph fontSize={16} margin="0">
                    Promocja obowiązuje wyłącznie dla nowych klientów Fortuny.
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0 0 12px 0">
                    Aby skorzystać z oferty wykonaj poniższe działania:
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0">
                    1. Wpisz dane w naszym serwisie - otrzymasz mail z linkiem
                    promocyjnym.
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0">
                    2. Zarejestruj się w Fortunie, z linku który otrzymasz w
                    mailu.
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0">
                    3. Zweryfikuj konto z wykorzystaniem profilu zaufanego lub
                    konta bankowego.
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0 0 12px 0">
                    4. Wpłać 20 zł pierwszego depozytu i obstaw 2 walki z
                    wydarzenia Prime 11.
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0">
                    System zweryfikuje Twoją rejestrację i prześle Ci kod PPV na
                    adres e-mail.
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0">
                    Warunkiem odbioru kodu, jest spełnienie wszystkich warunków
                    promocji.
                  </Paragraph>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <AccordionTitle>Kiedy dostanę kod ?</AccordionTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <Paragraph fontSize={16} margin="0">
                    Kod otrzymasz w ciągu 2 godzin, po spełnieniu warunków
                    promocji.
                  </Paragraph>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <AccordionTitle>
                    Czy promocja jest wyłącznie dla nowych klientów Fortuny ?
                  </AccordionTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <Paragraph fontSize={16} margin="0">
                    Tak, promocja jest wyłącznie dla nowych klientów Fortuny,
                    którzy zweryfikowali swoje konto.
                  </Paragraph>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <AccordionTitle>
                    Czy muszę zweryfikować konto ?
                  </AccordionTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <Paragraph fontSize={16} margin="0">
                    Tak, musisz zweryfikować konto w Fortunie, zajmie Ci to 3
                    minuty.
                  </Paragraph>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <AccordionTitle>
                    W jaki sposób dostanę kod PPV ?
                  </AccordionTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <Paragraph fontSize={16} margin="0">
                    Kod otrzymasz w wiadomości e-mail, po spełnieniu warunków
                    promocji. Kod PPV zrealizujesz na stronie{' '}
                    <Href
                      href="https://playlive.net/#/redeem-code"
                      target="_blank"
                    >
                      https://playlive.net/#/redeem-code
                    </Href>
                    {'. '}
                    Po poprawnym zalogowaniu się na platformie playlive.net
                    możesz zrealizować kod wpisując go w polu formularza{' '}
                    {'"Zrealizuj kod"'}
                  </Paragraph>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Footer>
        <Container>
          <Logotypes>
            <Logotype src="/images/logotypes/prime.png" />
            <Logotype src="/images/logotypes/fortuna.png" />
          </Logotypes>
        </Container>
      </Footer>

      <Section isPaddingBottom isPaddingTop color="#C9C9C9">
        <Container>
          <Title isCenter color="#000">
            Poprzednie akcje
          </Title>

          <OldCampaigns images={['/images/poster/gromda19.jpg']} />
        </Container>
      </Section>

      <Copyright>
        <Container>
          <CopyrightWrapper>
            <Text>© 2024 Watchcode. All rights reserved.</Text>
            <StyledLink
              fontSize="14px"
              fontFamily='"Reddit Sans", sans-serif'
              onClick={() => handleOpenPdf('polityka-prywatnosci')}
            >
              Polityka prywatności
            </StyledLink>
            <StyledLink
              fontSize="14px"
              fontFamily='"Reddit Sans", sans-serif'
              onClick={() => handleOpenPdf('regulamin')}
            >
              Regulamin
            </StyledLink>
            <StyledLink
              fontSize="14px"
              fontFamily='"Reddit Sans", sans-serif'
              onClick={() => handleOpenPdf('regulamin-promocji')}
            >
              Regulamin promocji
            </StyledLink>
          </CopyrightWrapper>
        </Container>
      </Copyright>
    </Content>
  );
};

export default LayoutPrimeShowMMA;
