import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  max-width: 292px;
  color: #00daff;
`;
