const successMessages = {
  addBookmaker: 'Bukmacher został dodany',
  deleteBookmaker: 'Usunięto bukmachera',
  addCampaign: 'Kampania została dodana',
  deleteCampaign: 'Usunięto kampanie',
  addLink: 'Link został wygenerowany',
  copied: 'Skopiowano!',
  layoutAdded: 'Dodano nowy layout',
  layoutDelete: 'Usunięto szablon',
  addCodes: 'Dodano kody do kampanii',
  editLayout: 'Szablon został zaktualizowany',
  editBookmaker: 'Bukmacher został zaktualizowany',
  editCampaign: 'Kampania została zaktualizowana',
  sendSMS: 'Kolejka wysyłania smsów została rozpoczęta',
};

export { successMessages };
