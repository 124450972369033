import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
`;

export const Text = styled.div`
  font-family:
    Chakra Petch,
    sans-serif;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 400;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  font-family:
    Chakra Petch,
    sans-serif;
  font-size: 42px;
  line-height: 1.2;
  font-weight: 700;
  color: #fff;
  border: 1px solid #666666;
  border-radius: 4px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;
