import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import {
  AddBookmaker,
  AddCampaign,
  AdminPanel,
  Dashboard,
  Home,
  Layouts,
  LinkGenerator,
  ListBookmakers,
  ListCampaignItem,
  ListCampaigns,
  ListUserItem,
  ListUsers,
  Login,
  PrivacyPolicy,
  Settings,
  Verifications,
  Rules,
  Functions,
  PromotionTerms,
  Terms,
} from './pages';
import Main from '@components/Main';
import { useTranslation } from 'react-i18next';
import ProtectedRoute from '@components/ProtectedRoute';
import { Roles } from '@enums/roles';
import { AdminPanelRoutes } from '@enums/routes';

function App() {
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Main />}>
          <Route path="/:locale/:campaign/:layout" element={<Home />} />
          <Route path="/:locale/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/:locale/promotion-terms" element={<PromotionTerms />} />
          <Route path="/:locale/terms" element={<Terms />} />
          <Route path="/admin-login" element={<Login t={t} />} />
          <Route
            path="*"
            element={
              <Navigate
                to="/pl/MXLqNuaubJFqwSeiDma1/mHIiicU6SZSB3sPshEZW"
                replace
              />
            }
          />
        </Route>
        <Route element={<ProtectedRoute requiredRole={Roles.ADMIN} />}>
          <Route path={'/admin-panel'} element={<AdminPanel />}>
            <Route index element={<Dashboard />} />
            <Route path={AdminPanelRoutes.LIST_USERS} element={<ListUsers />} />
            <Route
              path={`${AdminPanelRoutes.LIST_USERS}/:userId`}
              element={<ListUserItem />}
            />
            <Route
              path={AdminPanelRoutes.ADD_BOOKMAKER}
              element={<AddBookmaker />}
            />
            <Route
              path={AdminPanelRoutes.LIST_BOOKMAKER}
              element={<ListBookmakers />}
            />
            <Route
              path={AdminPanelRoutes.LINK_GENERATOR}
              element={<LinkGenerator />}
            />
            <Route path={AdminPanelRoutes.SETTINGS} element={<Settings />}>
              <Route index element={<Layouts />} />
              <Route
                path={AdminPanelRoutes.VERIFICATIONS}
                element={<Verifications />}
              />
              <Route path={AdminPanelRoutes.RULES} element={<Rules />} />
              <Route
                path={AdminPanelRoutes.FUNCTIONS}
                element={<Functions />}
              />
            </Route>
            <Route
              path={AdminPanelRoutes.LIST_CAMPAIGNS}
              element={<ListCampaigns />}
            />
            <Route
              path={`${AdminPanelRoutes.LIST_CAMPAIGNS}/:campaignId`}
              element={<ListCampaignItem />}
            />
            <Route
              path={AdminPanelRoutes.ADD_CAMPAIGN}
              element={<AddCampaign />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
